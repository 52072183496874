import {throttle} from 'lodash';

const stickyLogo = () => {
    const logo = document.querySelector('.js-header');
    const filter = document.querySelector('.js-filter');
    const onDesktop = window.matchMedia('(min-width: 768px)').matches;

    const amountscrolled = function () {
        const scrollTop = (window.pageYOffsets || (document.documentElement || document.body.parentNode || document.body).scrollTop);

        if (scrollTop >= 800) {
            logo.classList.add('on-scroll');

            if (filter) {
                filter.classList.add('on-scroll');
            }
        } else if (scrollTop < 800) {
            logo.classList.remove('on-scroll');

            if (filter) {
                filter.classList.remove('on-scroll');
            }
        }
    };

    const onResize = () => {
        window.addEventListener('resize', throttle(() => {
            amountscrolled();
        }, 100), false);
    };

    const onScroll = () => {
        window.addEventListener('scroll', throttle(() => {
            amountscrolled();
        }, 100), false);
    };

    if (onDesktop) {
        onResize();
        onScroll();
    }
};

export default () => {
    stickyLogo();
};
