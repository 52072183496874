import {throttle} from "lodash";

export default function initNewsSlider() {
    const elements = document.querySelectorAll('.js-news-slider');
    let onDesktop = window.matchMedia('(min-width: 576px)').matches;
    let swipers = [];

    function enableSwiper(element) {
        swipers.push(new Swiper(element, {
            spaceBetween: 17,
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                nextEl: element.parentElement.querySelector('.swiper-button-next'),
                prevEl: element.parentElement.querySelector('.swiper-button-prev'),
            },
        }));
    }

    function disableSwiper() {
        swipers.forEach((swiper) => {
            swiper.destroy();
        })
    }

    const initSlider = () => {
        elements.forEach((element) => {
            if (onDesktop) {
                enableSwiper(element);
            } else {
                disableSwiper();
            }
        });
    }

    window.addEventListener('resize', throttle(() => {
        onDesktop = window.matchMedia('(min-width: 576px)').matches;
        initSlider();
    }, 100), false);

    initSlider();
}
