export default function initTileSlider() {
    const tileSlider = document.querySelectorAll('.js-tile-slider');

    if (tileSlider) {
        tileSlider.forEach((element) => {
            new Swiper(element, {
                spaceBetween: 17,
                slidesPerView: 1,
                navigation: {
                    nextEl: element.parentElement.querySelector('.swiper-button-next'),
                    prevEl: element.parentElement.querySelector('.swiper-button-prev'),
                },
                a11y: {
                    prevSlideMessage: 'Zurück',
                    nextSlideMessage: 'Nächste',
                },
            });
        });
    }
}
