export default function toggleAccordion() {
    const accordionHeaders = document.querySelectorAll('.js-accordion');
    accordionHeaders.forEach((accordionHeader) => {
        const list = accordionHeader.parentElement.nextElementSibling;
        const realHeight = list.offsetHeight;
        list.setAttribute('data-height', `${realHeight}px`);
        list.style.height = 0;
        // eslint-disable-next-line no-param-reassign
        accordionHeader.addEventListener('click', () => {
            let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
            accordionHeader.classList.toggle('open');
            accordionHeader.setAttribute('aria-expanded', !expanded);
            expanded = !expanded;
            if (expanded) {
                list.style.height = list.getAttribute('data-height');
            } else {
                list.style.height = 0;
            }
        });
    });
}
