const toggleClasses = (elements, cssClasses) => {
    elements.forEach((element) => {
        cssClasses.forEach(cssClass => element.classList.toggle(cssClass));
    });
};

const leavingBorder = (navItem) => {
    const toggleStatus = () => {
        toggleClasses([navItem], ['leaving-border']);
    };

    navItem.addEventListener('mouseleave', toggleStatus);
    navItem.addEventListener('focusout', toggleStatus);
    navItem.addEventListener('animationend', toggleStatus);
};

export default () => {
    const navItems = document.querySelectorAll('.js-nav');

    navItems.forEach((el) => {
        const links = el.getElementsByTagName('a');

        Array.from(links).forEach((navItem) => {
            leavingBorder(navItem);
        });
    });
};
