import {throttle} from 'lodash';

class ScrollTop {
    constructor(element) {
        this.element = element;

        this.switchScrollButton();
        this.initEvents();
    }

    initEvents() {
        this.element.addEventListener('click', (event) => {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
        window.addEventListener('scroll', throttle(() => this.switchScrollButton(ScrollTop.getViewportOffset()), 500));
    }

    static getViewportOffset() {
        return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) / 2;
    }

    switchScrollButton(offset) {
        if (window.pageYOffset >= offset) {
            this.element.classList.remove('is-hidden');
            this.element.classList.add('is-visible');
        } else {
            this.element.classList.add('is-hidden');
            this.element.classList.remove('is-visible');
        }
    }
}

export default () => {
    const element = document.querySelector('.js-scrolltop');
    if (!element) {
        return false;
    }

    return new ScrollTop(element);
};
