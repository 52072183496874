export default function scrollToContent() {
    const element = document.querySelector('.js-scroll-to-content');
    if (!element) {
        return;
    }

    element.addEventListener('click', (event) => {
        event.preventDefault();

        const contentAnchor = document.getElementById('content');
        if (!contentAnchor) {
            return;
        }

        window.scrollTo({
            top: contentAnchor.getBoundingClientRect().top,
            behavior: 'smooth',
        });
    });
}
