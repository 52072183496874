export default function initVideosDesktopMobile() {
    const videos = document.querySelectorAll('.js-video-desktop-mobile');

    videos.forEach((video) => {
        const sources = video.querySelectorAll('source');
        const autoplay = video.autoplay;
        const onDesktop = window.matchMedia('(min-width: 768px)').matches;

        sources.forEach((source) => {
            if (onDesktop) {
                source.src = source.dataset.desktop;
            } else {
                source.src = source.dataset.mobile;
            }
        });

        video.load();

        if (autoplay) {
            video.play();
        }
    });
}
