export default function initCarouselGallery() {
    const carousels = document.querySelectorAll('.js-carousel-gallery');
    carousels.forEach((carousel) => {
        const slidesCount = carousel.querySelector('.js-carousel-wrapper').children.length;
        let firstSlide = true;

        if (slidesCount >= 2) {
            carousels.forEach((item) => {
                // eslint-disable-next-line no-new, no-undef
                const swiper = new Swiper(item, {
                    centeredSlides: true,
                    loop: true,
                    effect: 'fade',
                    speed: 1000,
                    fadeEffect: {
                        crossFade: true,
                    },
                    autoplay: {
                        delay: 6000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        // eslint-disable-next-line object-shorthand, func-names
                        renderBullet: function (index, className) {
                            return `<span class="${className}"></span>`;
                        },
                    },
                    keyboard: true,
                });

                swiper.on('slideChange', (swiperEvent) => {
                    if (firstSlide) {
                        swiperEvent.wrapperEl.classList.remove('first');
                    }

                    firstSlide = false;
                });
            });
        }
    });
}
