function animateCountUp(element) {
    const easeOut = t => t * (2 - t);
    let frame = 0;
    const {targetNumber} = element.dataset;
    const countTo = parseInt(targetNumber, 10);
    const animationDuration = 2000;
    const frameDuration = 1000 / 20;
    const totalFrames = Math.round(animationDuration / frameDuration);
    const counter = setInterval(() => {
        frame += 1;
        const progress = easeOut(frame / totalFrames);

        const currentCount = Math.round(countTo * progress);

        if (parseInt(element.innerHTML, 10) !== currentCount) {
            element.innerHTML = currentCount.toLocaleString('de-DE');
        }

        if (frame === totalFrames) {
            clearInterval(counter);
        }
    }, frameDuration);
}

export default function initCountUp() {
    if (window.IntersectionObserver) {
        const elements = document.querySelectorAll('.js-countup');
        const options = {
            threshold: 1.0,
        };

        const countUpObserver = new IntersectionObserver(
            (entries, observer) => entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animateCountUp(entry.target);
                    return observer.unobserve(entry.target);
                }

                return null;
            }),
            options,
        );

        elements.forEach((element) => {
            countUpObserver.observe((element));
        });
    }
}
