export default function initLoopWithDelay() {
    const videos = document.querySelectorAll('.js-video-loop-with-delay');

    videos.forEach((video) => {
        const delay = Number(video.dataset.delay);

        video.addEventListener('ended', () => {
            setTimeout(() => {
                video.play();
            }, delay);
        })
    });
}
