export default function initSlideshows() {
    const slideshows = document.querySelectorAll('.js-slideshow');

    if (slideshows) {
        slideshows.forEach((slideshow) => {
            new Swiper(slideshow, {
                centeredSlides: true,
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                allowSlidePrev: false,
                navigation: {
                    nextEl: slideshow.parentElement.querySelector('.js-swiper-button-next'),
                },
            });
        });
    }
}
