import 'intersection-observer';
import leavingBorder from './modules/leavingBorder';
import openMenu from './modules/openMenu';
import initNewsSlider from './modules/newsSlider';
import initSlideshows from './modules/slideShowSlider';
import initTileSlider from './modules/tileSlider';
import scrollTop from './modules/scrollTop';
import initCountUp from './modules/countUp';
import initCarouselGallery from './modules/carouselGallery';
import scrollToContent from './modules/scrollToContent';
import toggleAccordion from './modules/toggleAccordion';
import stopTransitionOnLoad from './modules/stopTransitionOnLoad';
import stickyLogo from './modules/stickyLogo';
import loopVideoWithDelay from './modules/loopWithDelay';
import initVideosDesktopMobile from './modules/videoDesktopMobile';
import sal from "sal.js";

window.addEventListener('load', () => {
    leavingBorder();
    openMenu();
    initSlideshows();
    initNewsSlider();
    initTileSlider();
    scrollTop();
    initCountUp();
    initCarouselGallery();
    scrollToContent();
    toggleAccordion();
    stopTransitionOnLoad();
    stickyLogo();
    loopVideoWithDelay();
    initVideosDesktopMobile();

    sal({
        threshold: 0.1,
        once: true,
    });
});
