class Hamburger {
    constructor(element) {
        this.element = element;

        this.element.addEventListener('click', () => this.handleClick());

        const targetId = this.element.dataset.targetId;

        this.target = document.querySelector(`#${targetId}`);
    }

    handleClick(event) {
        this.element.classList.toggle('open');
        this.target.classList.toggle('active');
    }
}

export default () => {
    const hamburgerElement = document.querySelector('.js-hamburger');

    const hamburger = new Hamburger(hamburgerElement);
};
